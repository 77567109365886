import { Router } from './Theme/degustationidr2024';

class Container{

    getRouter()
    {
        return Router;
    }

}

export {Container};