const API_URL = "https://admin.chefprolink.com/api";
const ROOT_URL = "https://admin.chefprolink.com";
const IMAGE_URL = "https://admin.chefprolink.com/image"


const APP_USER = "app.idr2024@groupescr.fr";
const APP_PWD = "pexmLWwOlfqhCMeTrTSCa";

const APP_THEME = "degustationidr2024";


const ANONYMOUS_USER = "d1a90c57-6826-470c-b0d3-7b99ee257019@app.idr.2024";
const ANONYMOUS_PWD = "KpWnADU1baac537nyDAF6";

const EVENEMENT_ID = "4";

export { API_URL, ROOT_URL, IMAGE_URL, APP_USER, APP_PWD, APP_THEME, ANONYMOUS_PWD, ANONYMOUS_USER,EVENEMENT_ID }