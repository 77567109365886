import {BoutonRond} from "../Elements/BoutonRond";
import {getIdentifiedUserToken, getThemeValue, setThemeValue} from "../../../Services/storage.service";
import {getUserName} from "../../../Services/generic.helpers";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

const Parameters = ({parameterClickHandler}) => {
    return (
        <BoutonRond className="gear-icon" clickHandler={() => parameterClickHandler()}>
            <svg xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 512 512"
                 className={`w-8 h-8 fill-primary rotate-45`}>
                <path
                    d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/>
            </svg>
        </BoutonRond>)
}



const ProfileIcon = () => {

    const user_token = getIdentifiedUserToken();
    const nom = getUserName(user_token);
    const {t} = useTranslation();

    if (!user_token) {
        return (
            <a href="/login">
                <BoutonRond className="profile-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" className={`w-8 h-8 fill-primary`}
                         viewBox="0 0 448 512">
                        <path
                            d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/>
                    </svg>
                </BoutonRond>
            </a>
        );
    }

    return (
        <a href="/myspace">
            <BoutonRond>
                <div className={`profile-icon flex w-8 h-8 text-primary items-center justify-center `}>
                    <span
                        className={`profile-icon__nom flex items-center justify-center font-bold`}>{nom[0]?.toUpperCase()}</span>
                </div>
            </BoutonRond>
        </a>
    );
};

const ThemeSelecteur = () => {

    const {t, i18n} = useTranslation();

    const darkthemeMq = window.matchMedia("(prefers-color-scheme: dark)");

    const initialThemeActivationState = getThemeValue(!!darkthemeMq.matches);
    const [ valueThemeActivated, setValueThemeActivated ] = useState(initialThemeActivationState);

    useEffect(() => {
        setThemeValue(valueThemeActivated);
    }, [valueThemeActivated]);

    return (
        <div className={`flex flex-row items-center gap-8`}>
            <span>{t('UI.Boutons.themes')}</span>
            <label className="flex cursor-pointer gap-2 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <circle cx="12" cy="12" r="5"/>
                    <path
                        d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4"/>
                </svg>
                <input type="checkbox" className="toggle theme-controller"
                       onChange={(e)=>setValueThemeActivated(!valueThemeActivated)}
                       value={`${valueThemeActivated ? "light" : ""}`} checked={valueThemeActivated}/>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                </svg>
            </label>
        </div>
    );
}

const BoutonSeDeconnecter = () => {

    const {t,i18n} = useTranslation();

    const navigate = useNavigate();

    return (
        <div className={`flex flex-row items-center`}>
            <Link to={`/logout`}>{t('UI.Boutons.sedeconnecter')}</Link>
        </div>
    )
}

const LangSelecteur = () => {
    const {t, i18n} = useTranslation();
    const handleLangSelection = (lng) => {
        i18n.changeLanguage(lng);
    }
    if (i18n.language.length > 2){
        i18n.changeLanguage(i18n.language.substring(0,2).toLowerCase());
    }

    return (
        <div className={`flex flex-row gap-8 items-center`}>
            <span>{t('UI.Boutons.langues')}</span>
            <div className={`flex flex-row gap-4 items-center flag-container`}>
                <div className={`p-[2px] border-2 ${'fr' === i18n.resolvedLanguage ? 'border-primary-content' : 'border-transparent'}`}>
                    <div className={`w-6 cursor-pointer `} onClick={() => handleLangSelection('fr')}>
                        <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-fr" viewBox="0 0 640 480">
                            <path fill="#fff" d="M0 0h640v480H0z"/>
                            <path fill="#002654" d="M0 0h213.3v480H0z"/>
                            <path fill="#ce1126" d="M426.7 0H640v480H426.7z"/>
                        </svg>
                    </div>
                </div>
                <div className={`p-[2px] border-2 ${'en' === i18n.resolvedLanguage ? 'border-primary-content' : 'border-transparent'}`}>
                    <div
                        className={`w-6 cursor-pointer `}
                        onClick={() => handleLangSelection('en')}>
                        <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-gb" viewBox="0 0 640 480">
                            <path fill="#012169" d="M0 0h640v480H0z"/>
                            <path fill="#FFF"
                                  d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"/>
                            <path fill="#C8102E"
                                  d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"/>
                            <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"/>
                            <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}

const ParameterPanel = ({inscope}) => {

    const isUserConnected = getIdentifiedUserToken();
    return (
        <div
            className={`${inscope ? '' : 'hidden'} z-20 fixed bottom-20 right-2 py-4 px-8 border border-neutral rounded-2xl bg-base-200 flex flex-col items-start drop-shadow-2xl`}>
            <div className={`pannel-container flex flex-col gap-6`}>
                <ThemeSelecteur/>
                <LangSelecteur/>
                {
                    isUserConnected &&
                    (<>
                            <hr/>
                            <BoutonSeDeconnecter/>
                        </>
                    )
                }
            </div>
        </div>
    )
}

const BarreDeNavigationInferieure = () => {

    const [asideMenuInScope, setMenuInScope] = useState(false);
    const [parameterPanelInScope, setParameterPanelInScope] = useState(false);

    const defineMenuScope = () => {
        setMenuInScope(!asideMenuInScope);
    }

    const toggleParameterPanelInScope = (event) => {
        setParameterPanelInScope(!parameterPanelInScope);
    }

    return (
        <>
        <div className="btm-nav bg-gradient-to-b from-primary from-10% via-secondary via-40% to-base-300 to-90%">
            <a href={"/myspace"}>
            <button className={`active`}>
                <BoutonRond>
                    <svg className={`w-8 h-8 fill-primary`} xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 576 512">
                        <path
                            d="M575.8 255.5c0 18-15 32.1-32 32.1l-32 0 .7 160.2c0 2.7-.2 5.4-.5 8.1l0 16.2c0 22.1-17.9 40-40 40l-16 0c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1L416 512l-24 0c-22.1 0-40-17.9-40-40l0-24 0-64c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32 14.3-32 32l0 64 0 24c0 22.1-17.9 40-40 40l-24 0-31.9 0c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2l-16 0c-22.1 0-40-17.9-40-40l0-112c0-.9 0-1.9 .1-2.8l0-69.7-32 0c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/>
                    </svg>
                </BoutonRond>


            </button>
            </a>
            <button>
                <Parameters parameterClickHandler={toggleParameterPanelInScope}/>
            </button>
            {/*<button className="active">*/}
            {/*    <svg*/}
            {/*        xmlns="http://www.w3.org/2000/svg"*/}
            {/*        className="h-5 w-5"*/}
            {/*        fill="none"*/}
            {/*        viewBox="0 0 24 24"*/}
            {/*        stroke="currentColor">*/}
            {/*        <path*/}
            {/*            strokeLinecap="round"*/}
            {/*            strokeLinejoin="round"*/}
            {/*            strokeWidth="2"*/}
            {/*            d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"/>*/}
            {/*    </svg>*/}
            {/*</button>*/}
        </div>
        <ParameterPanel inscope={parameterPanelInScope}/>
        </>
    );
}

export {BarreDeNavigationInferieure};