import {useTranslation} from "react-i18next";
import {IMAGE_URL} from "../../../../Config";
// import {EtiquetteDate} from "./EtiquetteDate";
import {transformDate} from "../../../../Services/generic.helpers";
import {TimeService} from "../../../../Services/time.service";

const CarteCompetiteur = ({evaluation}) => {

    const { t, i18n }  = useTranslation();

    const image_path = evaluation?.image?.path ?? '#';
    const image_url = `${IMAGE_URL}/${image_path}`;
    const image_alt = evaluation?.image?.alternate ?? "Image associée à l'évaluable";

    // const displayDateDeVisite = (evaluation) =>
    // {
    //     // date de visite calculée avec le fuseau.
    //     let computedDateDeVisite  = evaluation?.computedDateDeVisite ;
    //
    //     // let date_de_visite = evaluation?.computedDateDeVisite? new Date(evaluation?.computedDateDeVisite) : undefined;
    //     let date_de_visite = transformDate(evaluation?.computedDateDeVisite);
    //
    //     if ( ! date_de_visite ){
    //         return (<></>);
    //     }
    //
    //     return (<EtiquetteDate date={date_de_visite} />);
    //
    // }

    const displayStatus = (evaluation) => {

        // ajout au status en fonction de l'heure.

        const lng = i18n.language;

        let timeservice = new TimeService();
        let evaluation_start = timeservice.convertDateTimeStringToJSDateTimeObjet(evaluation.date_de_visite);
        let now = new Date();

        let badge = "badge-info text-info-content";
        let badge_content = t('UI.Badge.ouverte', lng);
        if (now < evaluation_start ){
            badge = "badge-neutral text-neutral-content";
            badge_content = t('UI.Badge.nonouverte', lng);
        }
        switch(evaluation.status){
            case 'en_cours':
                badge = "badge-primary text-primary-content";
                badge_content = t('UI.Badge.encours',lng);
                break;
            case 'terminee':
                badge = "badge-success text-success-content";
                badge_content = t('UI.Badge.terminee',lng);
                break;
        }

        let questionnaire_close = timeservice.convertDateTimeStringToJSDateTimeObjet(evaluation.questionnaire.date_fermeture);
        if (now > questionnaire_close ){
            badge = "badge-neutral text-neutral-content";
            badge_content = t('UI.Badge.fermee', lng);
        }
        return (
            <div className={`p-4`}>
                <div
                    className={`badge ${badge}`}>
                    {badge_content}
                </div>
            </div>

        );
    };

    return (
        <a
            className={`grid grid-cols-2 grid-flow-col rounded-xl overflow-hidden relative w-full max-w-xl min-h-[250px] shadow-2xl bg-secondary`}
            href={`/questionnaire/${evaluation.questionnaire.id}/competiteur/${evaluation.competiteur?.id}`}
        >
            <div className={`absolute top-0 l-0 w-full h-full col-start-1 col-span-2 row-start-1 row-span-2 m-0`}>
                <img className={`absolute top-0 left-0 w-full h-full object-contain object-center`}
                     src={image_url} alt={image_alt}/>
                <div className={`absolute top-0 left-0 w-full h-full bg-gradient-to-t from-base-100 from-5% via-transparent via-30% to-transparent`}></div>
            </div>
            <div className={`relative col-start-1 col-span-1`}>{displayStatus(evaluation)}</div>
            <div className={`relative col-start-1 col-span-2 justify-self-start self-end`}>
                <h3 className={`text-xl font-bold text-primary-content bg-gradient-to-r from-base-100 to-base-300 p-4 mb-8`}>
                    {evaluation.competiteur?.instance_name}
                </h3>
            </div>
        </a>
    );
};

export {CarteCompetiteur}