///
//
//
//  Override du start evaluation
//
///

/// Composants originaux
import {Await, useLoaderData} from "react-router-dom";
import {Suspense, useState} from "react";
import LoadingComponent from "../../../../Components/Elements/loading.component";
import {PresentationEvaluable} from "../../../../Bibliotheque/Composants/Metier/PresentationEvaluable";
import {SplashImage} from "../../Presentation/Metier/SplashImage";
import {CoordonneesEvaluable} from "../../../../Bibliotheque/Composants/Metier/CoordonneesEvaluable";
import {ControleursEvaluation} from "../../../../Bibliotheque/Composants/Metier/ControleurEvaluation";

/// Composants ré-écrits
import {ModaleEvaluation} from "./ModaleEvaluation";
import {useTranslation} from "react-i18next";


const StartEvaluationFactory = (props) => {

    const {key} = props;
    const loaded = useLoaderData();

    const [affichageEvaluation, setAffichageEvaluation] = useState(false);
    const {i18n} = useTranslation();

    // en mode défensif si le composant est utilisé en dehors d'un
    // suspense pour éviter de casser

    const toggleAffichageEvaluation = (event) => {
        setAffichageEvaluation(!affichageEvaluation);
    }

    const category_name = loaded?.data?.type_evaluable?.translatedNom[i18n.language];

    const nom_competiteur = loaded?.data?.splash?.nom;

    return (
        <Suspense fallback={<LoadingComponent/>} key={key}>
            <Await resolve={loaded.data}>
                { loaded?.data?.projection?.gallery[0]?.path ? (<SplashImage image_path={loaded.data.projection.gallery[0].path}/>) : (<></>) }
                <div className={`w-full px-2 lg:px-0 flex flex-col justify-start space-y-10`}>
                    <PresentationEvaluable splash_data={loaded.data.splash} category_name={category_name} questionnaire={loaded.data}/>
                        <CoordonneesEvaluable nom={nom_competiteur} lieu={loaded.data.projection.lieux[0]}
                                              telephone={loaded.data.splash.numeroTel}/>
                        <ControleursEvaluation status_evaluation={loaded.data.splash.evaluationStatus} verrou={loaded.data.splash.verrou} questionnaire={loaded.data}
                                               clickHandle={toggleAffichageEvaluation}/>
                        <ModaleEvaluation affichageEvaluation={affichageEvaluation}
                                          handleClose={toggleAffichageEvaluation}/>
                    </div>
            </Await>
        </Suspense>
);
}

export {
    StartEvaluationFactory}