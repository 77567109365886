import {Header} from "./Header";
import {Main} from "./Main";
import {Footer} from "./Footer";
import {FABHome} from "../Components/FABHome";
import {Toaster} from "react-hot-toast";

const Page = ({children}) => {
    return (
        <div className={`mainWrapper flex flex-col justify-between items-center min-h-screen h-full bg-base-100 text-base-content`}>
            {/*<Header />*/}
            <Main>{children}</Main>
            <Footer />
            <Toaster position="bottom-center"/>
            {/*<FABHome />*/}
        </div>
    )
};

export { Page };