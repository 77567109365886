import {useTranslation} from "react-i18next";

const QuestionEnumRange = ({questionNumber, representation, presentation, dispatcher, sectionNumber, ...component_data}) => {

    const { min, max, enumeration } = representation;

    const  length = max - min +1;

    // const valeur = presentation_data.valeur;
    const valeur = presentation?.valeur;

    const { t, i18n } = useTranslation();

    const handle_intitule = (component_data) => {
        const translatedIntitule = component_data?.translatedIntitule ?? {fr: '__intitule fr__', en: '__intitule en__'};
        const lang = i18n.language;
        return translatedIntitule[lang];
    }

    const handleChangeValue = (event) => {
        dispatcher({
            type:'setValeur',
            payload: {
                section_number: sectionNumber,
                question_number: questionNumber,
                valeur: event.target.value
            }});
    }

    const display_stars = (min,max,valeur) => {
        let input_array = [];
        valeur = parseInt(valeur);
        for (let i=min; i<=max; i++){
            input_array.push(
                (<input onChange={handleChangeValue} type={`radio`}
                        key={i}
                        name={`q-${questionNumber}`} className={`mask mask-star bg-accent`}
                        value={i}
                        checked={valeur===i}/>)
            );
        }
        return input_array;
    }

    const display_range = (valeur, enumeration) => {
        let _enumeration = enumeration[i18n.language];
        let select_array = [];
        for ( const property in _enumeration){
            select_array.push(
                <option key={property}
                value={property}
                // selected={valeur===property}
                >{_enumeration[property]}</option>
            )
        }
        return select_array;
    }

    return (
        <div className={`flex flex-col text-normal my-2 rounded-xl`}>
            <div className={`font-medium`}><span>{handle_intitule(component_data)}</span> {component_data.requis ? (<span className={`text-error`}>*</span>) : ''} </div>
            <div className={`rating space-x-2`}>
                <select className="select select-bordered w-full max-w-xs" onChange={handleChangeValue} value={valeur}>
                    {display_range(valeur, enumeration).map( option => option )}
                </select>
            </div>
        </div>
    );
};

export { QuestionEnumRange };