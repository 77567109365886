import {useTranslation} from "react-i18next";
import {transformDate} from "../../../Services/generic.helpers";
import {IMAGE_URL} from "../../../Config";

const PuceEvenementAgendaAVenir = ({evenement_agenda}) => {

    // si c'est lié à une animation, on link vers animation

    // si c'est lié à une évaluation, on link vers l'évaluation

    // si c'est lié à deux évaluations, on link vers un page tempon pour la sélection du questionnaire.

    const { i18n } = useTranslation();

    let month = Intl.DateTimeFormat(i18n.language, {month: 'short'})
        .format(transformDate(evenement_agenda.date_debut));
    let day = Intl.DateTimeFormat(i18n.language, {day: 'numeric'})
        .format(transformDate(evenement_agenda.date_debut));
    let heure = Intl.DateTimeFormat(i18n.language, {hour: 'numeric', minute: 'numeric'})
        .format(transformDate(evenement_agenda.date_debut));

    // si une évlauation => lien vers évaluation
    // si plusieurs évaluatins => lien vers la page evenement agenda

    const evaluations = evenement_agenda?.evaluations?? [];
    let link_value = `/evenement-agenda/${evenement_agenda.id}`;
    // if (evaluations.length > 1){
    //     link_value = `/evenement-agenda/${evenement_agenda.id}`;
    // }
    if (evaluations.length === 1 ){
        link_value = `/questionnaire/${evaluations[0].questionnaire.id}/competiteur/${evaluations[0].competiteur.id}`;
    }

    if (evaluations.length > 1 && evaluations[0].questionnaire?.presentation === 'finale'){
        link_value = `/questionnaire/${evaluations[0].questionnaire.id}`;
    }

    // const link_value = evaluations.length > 1 ? `/evenement-agenda/${evenement_agenda.id}`:
    //     `/questionnaire/${evaluations[0]?.questionnaire?.id}/competiteur/${evaluations[0].competiteur.id}`;

    return (
        <div className={`w-[250px] min-w-[250px] rounded relative grid grid-rows-2 grid-cols-1 h-[250px]`}>
            <img src={`${IMAGE_URL}/${evenement_agenda?.media?.path ?? "#"}`} className={`object-cover w-full h-full row-span-2 row-start-1 col-start-1 col-span-1`}/>
            <div className={`row-start-1 row-span-1 col-start-1 col-span-1 w-full`}>
                <div className={`w-auto flex px-4`}>
                    <div
                        className={`bg-black text-white font-sans text-md font-medium rounded-b px-8 py-1 flex-col justify-center items-center`}>
                        <div className={`flex w-full m-auto font-bold text-2xl`}>{day}</div>
                        <div className={`flex w-full m-auto`}>{month}</div>
                        <div className={`flex w-full m-auto text-sm font-light`}>{heure}</div>
                    </div>
                </div>
            </div>
            <div className={`col-start-1 col-span-1 row-span-1 row-start-2 font-sans font-semibold text-primary-content bg-base-200 p-4 justify-self-start self-end mb-4`}>
                {evenement_agenda.translatedNom[i18n.language]}
            </div>
        </div>
    )
}

export {PuceEvenementAgendaAVenir}